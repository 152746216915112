
















































































import {Component, Vue} from "vue-property-decorator";
import MyFooter from "@/components/MyFooter.vue";
import {apiOrder} from '@API/ApiOrder'
import {isWx as checkWx} from "@/utils/system";
import {RequestResult} from "@/utils/interface";

@Component({
  name: "OrderList",
  components: {MyFooter},
})
export default class Home extends Vue {
  private navCar: number = 0 // nav导航标记
  private orderList: any[] = [] // 订单列表
  private page: number = 1 // 当前页数

  private refreshLoading: boolean = false // 下拉刷新loading控制
  private hasNext: boolean = false // 分页是否有下一页
  private soleLoading: boolean = false // 触底loading控制
  private requestDis: boolean = false // 请求request禁用
  private requestErr: boolean = false // 请求失败

  private isWxBrowser: boolean = false // 是否来自微信浏览器
  created() {
    this.isWxBrowser = checkWx()
    let order_index_nav = sessionStorage.getItem('order_index_nav')
    if (order_index_nav) {
      this.navCar = parseInt(order_index_nav)
    }
    this.request()
  }


  // 触底
  onSole() {
    if (!this.requestDis && this.hasNext) {
      this.page++
      this.soleLoading = true
      this.request()
          .finally(() => {
            this.soleLoading = false
          })
          .catch(() => {
            this.page--
          })
    }
  }

  // 下拉刷新
  onRefresh() {
    this.page = 1
    this.refreshLoading = true
    this.request()
        .finally(() => {
          this.refreshLoading = false
        })
        .catch(() => {
          this.requestErr = true
        })
  }

  handleNavCarChange() {
    this.requestDis = false
    this.page = 1
    this.request()
    scrollTo(0, 0)
  }

  request(): Promise<RequestResult<any>> | any {
    if (this.requestDis) {
      return
    }
    const loading = this.$toast.loading({
      message: '加载中...',
      duration: 0,
    })

    let params: any = {
      page: this.page,
    }

    if (this.navCar != 3) {
      params.arrive_time = Number(this.navCar)
    } else {
      params.state = Number(this.navCar)
      params.arrive_time = null
    }

    this.requestDis = true
    return apiOrder.orderList(params)
        .finally(() => {
          this.requestDis = false
          loading.clear()
        })
        .then(res => {
          if (res.data) {
            this.hasNext = this.page * res.data.per_page < res.data.total
            if (this.page === 1) {
              this.orderList = res.data.data
            } else {
              this.orderList = [...this.orderList, ...res.data.data]
            }
          }
        })
        .catch(e => {
          this.$dialog({
            title: e.code === '40026' ? '提示' : '获取列表失败',
            message: e.message
          })
        })
  }

  handleNavigateToShow(order_no: string) {
    sessionStorage.setItem('order_index_nav', this.navCar.toString())
    this.$router.push({
      name: 'orderInfo', params: {
        order_no: order_no
      }
    })
  }

}
